import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import Image from "./Image";

const VertikalniShtori = ({ frontmatter }) => {
  return (
    <div className="container d-flex flex-wrap pt-5 pt-lg-6">
      <h3 className="col-12 pb-2">{frontmatter.header}</h3>
      <div className="d-flex justify-content-lg-start justify-content-center flex-column col-12 px-md-0 px-2 col-lg-6 px-0">
        <Carousel
          variant="dark"
          className="col-md-8 col-lg-12 align-self-center flex-md-shrink-1"
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="#212529" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="#212529" />}
        >
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="vert_shtori/1.jpg"
              alt="Ветикална щора"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="vert_shtori/2.jpg"
              alt="Вертикална щора"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
        </Carousel>
        <div className="d-flex justify-content-center my-3">
          <Link className="btn btn-primary" to="/gallery/#vert_shtori" activeClassName="active">
            Към Галерията
          </Link>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="mb-2">
          <strong>Елегантно и стилно решение за всеки вкус...</strong>
        </div>
        <div>
          Вертикалните щори придават модерен завършен вид на вашето помещение. Те заместват изцяло
          пердетата и са идеални за контролиране на количеството светлина, което влиза в едно
          помещение.
        </div>
      </div>
      <div className="col-12 mt-4 mt-lg-0">
        <div className="mb-2">
          <strong>Богат избор на цветове и модели...</strong>
        </div>
        <div>
          Ние от Бисер Пласт Партнерс предлагаме голямо разнообразие от цветове и модели на
          верикални щори:
          <ul className="pt-2">
            <li>
              <b>Щори с ленти от плат. </b> Платовете биват с различно пропускливост - от
              полупрозрачни до напълно затъмняващи платове.
            </li>
            <li>
              <b>Перде щори.</b> Различни цветове, с възможност за комбиниране.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

VertikalniShtori.propTypes = {
  frontmatter: PropTypes.object,
};

VertikalniShtori.defaultProps = {
  frontmatter: {},
};

export default VertikalniShtori;
